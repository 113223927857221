import React from 'react';
import { Modal, Box, Typography, Button, IconButton } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

interface ColumnProps {
  icon: React.ReactNode;
  title: string;
  text: string;
  button: string;
  url: string;
}

const Column: React.FC<ColumnProps> = ({ icon, title, text, button, url }) => {
  const handleButtonClick = () => {
    window.open(url, '_blank');
  };

  return (
    <Box
      sx={{
        padding: '24px 0',
        borderBottom: '1px solid #0d58a8',
        ':last-child': {
          borderBottom: 'none',
        },
      }}
    >
      <Box sx={{ '& .MuiSvgIcon-root': { color: '#0d58a8', fontSize: '32px' } }}>{icon}</Box>
      <Typography variant="h6" color={'#0d58a8'} fontWeight={'bold'} marginBottom={'10px'}>
        {title}
      </Typography>
      <Typography variant="body2">{text}</Typography>
      <Button sx={{ '&.MuiButton-root': { marginTop: '16px' } }} variant="outlined" color="primary" onClick={handleButtonClick}>
        {button}
      </Button>
    </Box>
  );
};

interface ModalComponentProps {
  open: boolean;
  onClose: () => void;
}

const ModalComponent: React.FC<ModalComponentProps> = ({ open, onClose }) => {
    const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          width: 500,
          maxWidth: '86%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 2,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        }}
      >
        <IconButton sx={{ position: 'absolute', top: '8px', right: '8px', color: '#0d58a8' }} aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Column
          icon={<ChatIcon />}
          title={t('ChatWithUsTitle')}
          text={t('ChatWithUsText')}
          button={t('ChatWithUsButton')}
          url="https://www.pensioenschoonmaak.nl/contact/chat"
        />
        <Column icon={<CallIcon />} title={t('CallUsTitle')} text={t('CallUsText')} button="0205835100" url="tel:0205835100" />
        <Column
          icon={<EmailIcon />}
          title={t('EmailUsTitle')}
          text={t('EmailUsText')}
          button={t('EmailUsButton')}
          url="https://www.pensioenschoonmaak.nl/contact/stuur-ons-een-bericht"
        />
      </Box>
    </Modal>
  );
};

export default ModalComponent;
