import { Box, Container, Grid, styled, Button } from '@mui/material';
import Globe from '../assets/images/globe-sharp-light.svg';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import LanguageList from '../components/LanguageList';


const WrapButton = styled(Box)({
  position: 'fixed',
  bottom: '34px',
  left: '0',
  width: '100%',
  padding: '0 20px',
  textAlign: 'center',
  boxSizing: 'border-box',

  Button: {
    maxWidth: '400px',
  },
});

interface LanguagePickerProps {
  onNext: () => void;
}

const LanguagePicker: React.FC<LanguagePickerProps> = ({ onNext }) => {
  const { i18n, t } = useTranslation();
  const [selectedLanguage, setLanguage] = useState<string | null>(null);

  const handleLanguageClick = (language: string) => {
    i18n.changeLanguage(language);
    if (selectedLanguage === language) {
      setLanguage(null);
    } else {
      setLanguage(language);
    }
  };

  const handleContinueClick = () => {
    if (selectedLanguage) {
      onNext();
    }
  };

  return (
    <Container sx={{ padding: '0 10px' }}>
      <Grid container justifyContent={'center'}>
        <Grid item xs={12}>
          <Box display={'flex'} justifyContent={'center'} sx={{ padding: '26px' }}>
            <img src={Globe} alt="Globe" />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <LanguageList handleLanguageClick={handleLanguageClick} selectedLanguage={selectedLanguage} />
          {selectedLanguage && (
            <WrapButton>
              <Button variant="contained" fullWidth onClick={handleContinueClick}>
                {t("Continue in language")}
              </Button>
            </WrapButton>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default LanguagePicker;
