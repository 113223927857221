import React from 'react';
import { styled, keyframes } from '@mui/system';

const TypingIndicatorContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
}));

const TypingDot = styled('div')(({ theme }) => ({
  display: 'inline-block',
  width: '8px',
  height: '8px',
  marginTop: '10px',
  marginRight: '4px',
  backgroundColor: '#1976D2',
  borderRadius: '50%',
  animation: `${rotateAnimation} 1.5s infinite ease-in-out`,
}));

const rotateAnimation = keyframes({
    '0%, 20%, 100%': {
        opacity: 0.5,
      },
      '50%': {
        opacity: 1,
      },
  });

export default function TypingIndicator() {
  return (
    <TypingIndicatorContainer>
      <TypingDot />
      <TypingDot />
      <TypingDot />
    </TypingIndicatorContainer>
  );
}
