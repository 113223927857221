import { Box, Container, Grid, styled, keyframes } from '@mui/material';
import Header from '../components/Header';
import Loader from '../assets/images/loader.svg';
import Jamila from '../assets/images/jamila.png';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const rotateAnimation = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
});

const LoaderBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '86px',
  marginBottom: '20px',
  animation: `${rotateAnimation} 2s linear infinite`,
});

const LoaderBoxPreview = styled(Box)({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  img: {
    width: '60px',
    animation: `${rotateAnimation} 2s linear infinite`,
  },
});

const PreviewBox = styled(Box)({
  marginTop: '20px',
  '& .uploadingDoc': {
    width: '100%',
    opacity: '0.3',
  },
});

const GreetingBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',

  img: {
    width: '75px',
    marginLeft: '20px',
  },
  '& .text-output': {
    padding: '10px',
    backgroundColor: '#fff',
    color: '#313233',
    lineHeight: '1.5',
    borderRadius: '6px',
  },
});

interface GreetingProps {
  onNavigateToTerms: () => void;
  firstText: string;
  secondText?: string;
  doc?: string;
}

const Greeting = ({ onNavigateToTerms, firstText, secondText, doc }: GreetingProps) => {
  const { t } = useTranslation();
  const [firstBubble, setFirstBubble] = useState(true);

  useEffect(() => {
    if (secondText) {
      setTimeout(() => {
        setFirstBubble(false);
      }, 3000);
    }
  }, [secondText]);

  return (
    <>
      <Header onNavigateToTerms={onNavigateToTerms} disableHeaderButtons />
      <Container>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} md={6} display={'flex'} direction={doc ? 'column-reverse' : 'column'}>
            <Box sx={{ position: 'relative' }}>
              {doc ? (
                <PreviewBox>
                  <LoaderBoxPreview>
                    <img src={Loader} alt="Loading..." />
                  </LoaderBoxPreview>
                  <img className="uploadingDoc" src={doc} alt={t(firstText)} />
                </PreviewBox>
              ) : (
                <LoaderBox>
                  <img src={Loader} alt="Loading..." />
                </LoaderBox>
              )}
            </Box>
            <GreetingBox sx={{marginTop: doc ? '86px' : '0' }}>
              <img src={Jamila} alt="Textbuddy" />
              {firstBubble ? <Box className="text-output">{t(firstText)}</Box> : <Box className="text-output">{t(secondText!)}</Box>}
            </GreetingBox>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Greeting;
