import React, { useRef, useEffect } from 'react';

interface AudioPlayerProps {
  src: string;
  onPlay: () => void;
  pause: boolean | undefined;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ src, pause, onPlay }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    const audio = audioRef.current;

    if (audio) {
      if (pause) {
        audio.pause();
        audio.currentTime = 0;
      }
    }
  }, [pause]);

  useEffect(() => {
    const audio = audioRef.current;

    if (audio) {
      const handleCanPlay = () => {
        if (!pause) {
          audio.play().catch((error) => {
            console.error('Audio play error:', error);
          });
        }
      };

      audio?.addEventListener('canplay', handleCanPlay);

      return () => {
        audio?.removeEventListener('canplay', handleCanPlay);
      };
    }
  }, [pause]);

  return <audio controls autoPlay ref={audioRef} src={src} onPlay={onPlay} />;
};

export default React.memo(AudioPlayer);
