import { useNavigate } from "react-router-dom";
import Terms from "../views/Terms";

const WebsiteTermsFlow = () => {
  const navigate = useNavigate();
  return (
    <Terms onNext={() => navigate("/website")} />
  )
}

export default WebsiteTermsFlow