import React, { useState, useRef, useEffect } from 'react';
import { Avatar, Box, Button, keyframes, styled } from '@mui/material';
import Header from '../components/Header';
import CloseCircleIcon from '../assets/images/icon-close-circle.svg';
import stateMachineData from '../data/dataFlow.json';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { languages } from '../data/languages';
import Loading from './Loading';
import TypingIndicator from '../components/TypingIndicator';
import AudioPlayer from '../components/AudioPlayer';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useGetGlossaryEntryCallback } from '../hooks/api/apg-middleware/useGetGlossaryEntryCallback';
import { useMediaQuery } from 'react-responsive';

import ImageUploader from '../components/ImageUploader';
import ContactUsModal from '../components/ContactUsModal';
import LanguagePicker from './LanguagePicker';
import Terms from './Terms';
import { MIDDLEWARE, MIDDLEWARE_DOCUMENTS } from '../config';

const ChatBox = styled(Box)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 100px)',
  maxWidth: '600px',
  margin: '36px auto 0',
  backgroundColor: '#fff',
  borderRadius: '30px 30px 0 0',
  overflow: 'hidden',

  '&:before': {
    content: '""',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '40px',
    background: 'linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 50%, transparent 100%)',
    zIndex: 2,
  },
});

const CloseChatBox = styled(Box)({
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  zIndex: 3,

  '& .MuiButton-root.MuiButton-contained': {
    padding: '8px 32px',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
    boxShadow: '0 4px 4px 0 rgba(0,0,0,.25)',

    '& .btn-text': {
      marginLeft: '16px',
      fontSize: '13px',
      fontWeight: '400',
    },
  },
});

const ChatBlockWrap = styled(Box)({
  '&:first-of-type': {
    marginTop: '40px',
  },
});

const ChatBlock = styled(Box)({
  display: 'flex',
  opacity: 0,
  transform: 'translateY(20px)',
  transition: 'opacity 0.5s ease, transform 0.5s ease',

  '&.visible': {
    display: 'flex',
    opacity: 1,
    transform: 'translateY(0)',
  },
});

const ChatAvatarBox = styled(Box)({
  minWidth: '44px',
  marginRight: '13px',
  paddingTop: '25px',
});

const ScrollableBlock = styled(Box)({
  overflow: 'auto',
  padding: '0 22px 90px',
});

const ChatContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: '10px',

  '& .name': {
    marginBottom: '5px',
    color: '#B6BCC0',
    fontSize: '17px',
  },

  '& .MuiBox-root': {
    padding: '14px 10px',
    backgroundColor: '#E2DDD1',
    lineHeight: '1.5',
    borderRadius: '8px',
  },

  '& .title': {
    display: 'flex',
    marginBottom: '20px',
    fontWeight: '600',
  },

  '& a': {
    wordBreak: 'break-word',
  },
});

const showBlockAnimation = keyframes({
  '0%': {
    opacity: '0',
    transform: 'translateY(20px)',
  },
  '100%': {
    opacity: '1',
    transform: 'translateY(0)',
  },
});

const ChatContentBoxAnimated = styled(ChatContentBox)({
  animation: `${showBlockAnimation} 0.5s ease`,
});

const DateBlock = styled(Box)({
  display: 'flex',
  marginTop: '2px',
  paddingLeft: '64px',
  paddingRight: '4px',
  color: '#B6BCC0',
  fontSize: '17px',
});

const ActionsBlock = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  flexWrap: 'wrap',
  marginTop: '10px',

  '& .MuiButton-root': {
    margin: '0 5px 5px',

    '&.active': {
      backgroundColor: '#0D58A8',
      color: '#fff',
    },
  },
});

interface StateMachine {
  start: string;
  ocr: string;
  states: {
    [key: string]: {
      message: string[];
      messageEnd?: string[];
      apiCall?: string;
      transitions: string[];
      options?: string[];
      text?: string;
      redirectLink?: string;
      component?: string;
    };
  };
}

interface outputBlock {
  type: 'user' | 'bot' | 'bot-sound';
  text?: string;
  sound?: string;
  soundVisible?: boolean;
  soundPause?: boolean;
  actions?: {
    api: string | undefined;
    text: string;
    active: boolean;
    transition: string;
    time?: string;
    action?: () => void;
  }[];
}

const Chat: React.FC = () => {
  const stateMachine: StateMachine = stateMachineData;
  const [currentComponent, setCurrentComponent] = useState('chat');
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [initialOpening, setInitialOpening] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingDoc, setLoadingDoc] = useState(false);
  const [currentState, setCurrentState] = useState<string>(initialOpening ? 'welcome' : stateMachine.start);
  const [hashId, setHashId] = useState<string | number>();
  const [outputBlocks, setOutputBlocks] = useState<outputBlock[]>([]);
  const [visibleBlocks, setVisibleBlocks] = useState(stateMachine.states[currentState].message!.length - 1);
  const [showTypingIcon, setShowTypingIcon] = useState(true);
  const chatBoxRef = useRef<HTMLDivElement | null>(null);
  const [audioMode, setAudioMode] = useState(false);
  const imageUploaderRef = useRef<HTMLInputElement | null>(null);
  const getGlossaryEntryCallback = useGetGlossaryEntryCallback();
  const [searchParams] = useSearchParams();
  const [capturedImage, setCapturedImage] = useState<File | undefined>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (
      currentComponent === 'chat' &&
      (currentState === stateMachine.start || currentState === 'initialRoutine') &&
      currentState !== searchParams.get('initialState') &&
      stateMachine.states[searchParams.get('initialState') ?? 'undefined']
    ) {
      setCurrentState(searchParams.get('initialState') ?? stateMachine.start);
    }
  }, [currentState, stateMachine.start, stateMachine.states, searchParams, currentComponent]);

  useEffect(() => {
    if (currentComponent === 'chat' && currentState === 'initialRoutine' && !stateMachine.states[searchParams.get('initialState') ?? 'undefined']) {
      setCurrentState(stateMachine.start);
    }
  }, [currentState, stateMachine.start, stateMachine.states, searchParams, currentComponent]);

  const handleUploadButtonClick = () => {
    imageUploaderRef.current?.click();
  };

  const handleUploadSuccess = (response: any) => {
    console.log('Upload successful. Response:', response);
    setCurrentState(stateMachine.ocr);
    setHashId(response);
  };

  const handleUploadFailure = (error: any) => {
    console.error('Error during upload:', error);
  };

  const handleUploadCancel = () => {
    console.log('Upload canceled or file picker closed without selecting an image.');
  };

  const handleImageCapture = (imageFile: File) => {
    setCapturedImage(imageFile);
  };

  useEffect(() => {
    axios
      .get(`${MIDDLEWARE_DOCUMENTS}`)
      .then((response) => {
        setHashId(response.data[0].hashId);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  function textToBlocks(text: string): string[] {
    const linkRegex = /(https?:\/\/\S+)/g;

    const blocks = text.split(/\n-/);

    const formattedBlocks = blocks.map((block) => {
      block = block.replace(/^-/, '').trim();

      const blockWithLinks = block.replace(linkRegex, (match, link) => {
        return `<a href="${link}" target="_blank">${link}</a>`;
      });

      return blockWithLinks;
    });

    return formattedBlocks;
  }

  function textToBlocksFullLetterDocument(text: string): string[] {
    const linkRegex = /(https?:\/\/\S+)/g;

    const blocks = text.split(/\n{2,}/);

    const formattedBlocks = blocks.map((block) => {
      const blockWithLinks = block.replace(linkRegex, (match, link) => {
        return `<a href="${link}" target="_blank">${link}</a>`;
      });

      const indexOfNewLine = blockWithLinks.indexOf('\n');
      if (indexOfNewLine !== -1) {
        const beforeNewLine = blockWithLinks.substring(0, indexOfNewLine);
        const afterNewLine = blockWithLinks.substring(indexOfNewLine);
        return `<b class="title">${beforeNewLine}.</b><p>${afterNewLine}</p>`;
      }

      return blockWithLinks;
    });

    return formattedBlocks;
  }

  const actionsBlock = (state?: string, loudMode?: boolean, noSoundMode?: boolean) => {
    const transitions = state ? stateMachine.states[state].transitions : stateMachine.states[currentState].transitions;
    const stateOcr = searchParams.get('initialState') === 'idleNotification';
    const actionsBlock: outputBlock = {
      type: 'user',
      actions: [],
    };

    const lastUserAction = outputBlocks
      .slice()
      .reverse()
      .find(
        (item) => item.type === 'user' && item.actions && item.actions.length > 0 && item.actions[0].transition !== 'stopReadAloud' && item.actions[0].transition !== 'readAloud' && item.actions[0].active === true
      );

    if (!noSoundMode && (state === 'readAloud' || state === 'stopReadAloud') && !stateOcr) {
      if (lastUserAction) {
        const newState = lastUserAction.actions![0].transition === 'showEntireLetter' ? 'simplifyLetter' : 'showEntireLetter';
        actionsBlock.actions!.push({
          transition: newState,
          text: t(stateMachine.states[newState].text!),
          api: stateMachine.states[newState].apiCall,
          active: false,
        });
      } else {
        actionsBlock.actions!.push({
          transition: 'simplifyLetter',
          text: t(stateMachine.states['simplifyLetter'].text!),
          api: stateMachine.states['simplifyLetter'].apiCall,
          active: false,
        });
      }
    }

    if (!noSoundMode && loudMode) {
      actionsBlock.actions!.push({
        transition: 'stopReadAloud',
        text: t(stateMachine.states['stopReadAloud'].text!),
        api: '',
        active: false,
      });
    }

    if (!noSoundMode && !loudMode && state !== 'welcome') {
      actionsBlock.actions!.push({
        transition: 'readAloud',
        text: t(stateMachine.states['readAloud'].text!),
        api: '',
        active: false,
      });
    }

    if (state === 'helpWithLetterYes') {
      actionsBlock.actions!.push({
        transition: 'openCamera',
        text: t(stateMachine.states['openCamera'].text!),
        api: '',
        action: handleUploadButtonClick,
        active: false,
      });
    }

    transitions.map((transition) => {
      actionsBlock.actions!.push({
        transition: transition,
        text: t(stateMachine.states[transition].text!),
        api: stateMachine.states[transition].apiCall ? stateMachine.states[transition].apiCall : '',
        active: false,
      });
    });

    return actionsBlock;
  };

  useEffect(() => {
    if (hashId) handleAction(stateMachine.states[currentState].apiCall!, currentState, actionsBlock().actions!);
  }, [hashId]);

  useEffect(() => {
    if (currentState === 'glossaryIntro' || currentState === 'idleNotification' || currentState === 'greetUser')
      handleAction(stateMachine.states[currentState].apiCall!, currentState, actionsBlock().actions!);
  }, [currentState]);

  const calculateDelay = (text: string) => {
    if (text) {
      // Adjust this factor to control the speed based on text size
      const speedFactor = 20;

      // Calculate the delay based on the length of the text
      const delay = Math.max(text.length * speedFactor, 1000);

      return delay;
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const displayNextBlock = () => {
      if (visibleBlocks >= outputBlocks.length - 1) {
        setShowTypingIcon(false);
        clearInterval(intervalId);
        return;
      }

      if (visibleBlocks >= outputBlocks.length - 3) setVisibleBlocks((prevVisibleBlocks) => prevVisibleBlocks + 2);
      else setVisibleBlocks((prevVisibleBlocks) => prevVisibleBlocks + 1);

      if (visibleBlocks >= outputBlocks.length - 2) setShowTypingIcon(false);
      else setShowTypingIcon(true);
    };

    const textToDisplay = outputBlocks[visibleBlocks]?.text;
    const delay = textToDisplay ? calculateDelay(textToDisplay) : 1;

    if (!loading) intervalId = setInterval(displayNextBlock, delay);

    return () => clearInterval(intervalId);
  }, [visibleBlocks, outputBlocks, loading]);

  const scrollToTheLatestBlock = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTo({
        top: chatBoxRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    scrollToTheLatestBlock();
  }, [visibleBlocks, showTypingIcon]);

  function getFormattedTime() {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  const getFirstMessagesFromTransition: (transition: string) => outputBlock[] = (transition: string) => {
    if (stateMachine.states[transition].message && stateMachine.states[transition].message!.length > 0) {
      return stateMachine.states[transition].message!.map((textBlock) => ({
        type: 'bot',
        text: t(textBlock),
      }));
    } else {
      return [];
    }
  };

  const getLastMessagesFromTransition: (transition: string) => outputBlock[] = (transition: string) => {
    if (stateMachine.states[transition].messageEnd && stateMachine.states[transition].message!.length > 0) {
      return stateMachine.states[transition].messageEnd!.map((textBlock) => ({
        type: 'bot',
        text: t(textBlock),
      }));
    } else {
      return [];
    }
  };

  const updateOutputs = (transition: string, loudMode?: boolean, noSoundMode?: boolean) => {
    const filteredActions = outputBlocks[outputBlocks.length - 1].actions!.filter((action) => action.transition === transition);
    filteredActions[0].active = true;
    filteredActions[0].time = getFormattedTime();

    setOutputBlocks((prevBlocks) => {
      prevBlocks[prevBlocks.length - 1].actions = filteredActions;
      const transitionStartBlocks = getFirstMessagesFromTransition(transition);

      return [...prevBlocks, ...transitionStartBlocks, actionsBlock(transition, loudMode, noSoundMode)];
    });
  };

  const handleAction = (api: string, transition: string, actions: { api: string | undefined; text: string; transition: string }[]) => {
    setCurrentState(transition);

    const updateOutputs = (loudMode?: boolean, noSoundMode?: boolean) => {
      const filteredActions = outputBlocks[outputBlocks.length - 1].actions!.filter((action) => action.transition === transition);
      filteredActions[0].active = true;
      filteredActions[0].time = getFormattedTime();

      setOutputBlocks((prevBlocks) => {
        prevBlocks[prevBlocks.length - 1].actions = filteredActions;
        const transitionStartBlocks = getFirstMessagesFromTransition(transition);

        return [...prevBlocks, ...transitionStartBlocks, actionsBlock(transition, loudMode, noSoundMode)];
      });
    };

    if (audioMode) {
      setOutputBlocks((prevBlocks) => {
        prevBlocks.forEach((element) => {
          if (element.type === 'bot-sound') {
            element.soundPause = true;
          }
        });

        return [...prevBlocks];
      });
    }
    if (transition === 'readAloud') {
      setAudioMode(true);

      const lastSoundBlock = outputBlocks
        .slice()
        .reverse()
        .find((item) => item.type === 'bot-sound');

      const targetSoundBlock = lastSoundBlock || outputBlocks[outputBlocks.length - 2];

      targetSoundBlock.soundVisible = true;
      targetSoundBlock.soundPause = false;

      const filteredActions = outputBlocks[outputBlocks.length - 1].actions!.filter((action) => action.transition === transition);
      filteredActions[0].active = true;
      filteredActions[0].time = getFormattedTime();

      setOutputBlocks((prevBlocks) => {
        prevBlocks[prevBlocks.length - 1].actions = filteredActions;
        const transitionStartBlocks = getFirstMessagesFromTransition(transition);

        return [...prevBlocks, ...transitionStartBlocks, actionsBlock(transition, true)];
      });
    }
    if (transition === 'stopReadAloud') {
      setAudioMode(false);

      updateOutputs(false);
    }
    if (transition === 'greetUser') {
      setOutputBlocks((prevBlocks) => {
        const transitionStartBlocks = getFirstMessagesFromTransition(transition);

        setTimeout(() => {
          setLoading(false);
        }, 5000);

        return [...prevBlocks, ...transitionStartBlocks, actionsBlock(transition, undefined, true)];
      });
    }
    switch (api) {
      case undefined:
        break;
      case 'glossaries/glossary':
        getGlossaryEntryCallback.getGlossaryEntry(searchParams.get('term') ?? 'pention').then((processDto) => {
          const soundBlock: outputBlock = {
            type: 'bot-sound',
            sound: processDto?.processOutputUrl,
            soundVisible: audioMode ? true : false,
            soundPause: false,
          };

          setOutputBlocks((prevBlocks) => {

            const transitionStartBlocks = getFirstMessagesFromTransition(transition);
            const newBlock: outputBlock = {
              type: 'bot',
              text: processDto?.processOutputText,
            };
            const transitionEndBlocks = getLastMessagesFromTransition(transition);

            setTimeout(() => {
              setLoading(false);
            }, 5000);

            return [...prevBlocks, ...transitionStartBlocks, newBlock, soundBlock, ...transitionEndBlocks, actionsBlock(transition, undefined, true)];
          });
        });
        break;
      default:
        const fetchData = () => {
          if (transition === stateMachine.start || transition === stateMachine.ocr) setLoading(true);
          if (transition === stateMachine.ocr) setLoadingDoc(true);
          const ocrType = typeof hashId === 'number';
          const url = `${MIDDLEWARE}${ocrType ? 'ocrs' : 'documents'}/${hashId}/${api}?language=${languages[i18n.language].name}`;

          const processData = (response: any) => {
            const textBlocks: string[] = ocrType || api === 'simplifications' ? textToBlocks(response.data.outputText) : textToBlocksFullLetterDocument(response.data.outputText);
            let filteredActions: any = undefined;
            const soundBlock: outputBlock = {
              type: 'bot-sound',
              sound: response.data.outputUrl,
              soundVisible: audioMode ? true : false,
              soundPause: false,
            };

            if (outputBlocks.length > stateMachine.states[currentState].message!.length) {
              filteredActions = outputBlocks[outputBlocks.length - 1].actions!.filter((action) => action.transition === transition);
              if (filteredActions[0]) {
                filteredActions[0].active = true;
                filteredActions[0].time = getFormattedTime();
              }
            }

            setOutputBlocks((prevBlocks) => {
              if (filteredActions) prevBlocks[prevBlocks.length - 1].actions = filteredActions;
              const transitionStartBlocks = getFirstMessagesFromTransition(transition);
              const apiBlocks: outputBlock[] = textBlocks.map((textBlock) => ({
                type: 'bot',
                text: textBlock,
              }));

              setTimeout(() => {
                setLoading(false);
                setLoadingDoc(false);
                scrollToTheLatestBlock();
              }, 5000);

              return [...prevBlocks, ...transitionStartBlocks, ...apiBlocks, soundBlock, actionsBlock(transition, audioMode)];
            });
          };

          const fetchWithRetry = () => {
            axios
              .get(url)
              .then((response) => {
                if (response.data.outputText !== null) {
                  processData(response);
                } else {
                  setTimeout(fetchWithRetry, 10000);
                }
              })
              .catch((error) => {
                console.error('Error during fetch:', error);
                setTimeout(fetchWithRetry, 10000);
              });
          };

          fetchWithRetry();
        };

        fetchData();
    }
    if (transition === 'anotherQuestion' || transition === 'helpWithLetterNo') {
      updateOutputs(undefined, true);
    }
    if (transition === 'welcome') {
      setOutputBlocks([...getFirstMessagesFromTransition(transition), actionsBlock(transition, false, false)]);
    }
    if (transition === 'helpWithLetter') {
      updateOutputs(undefined, true);
    }
    if (transition === 'helpWithLetterYes') {
      updateOutputs(undefined, true);
      handleUploadButtonClick();
    }
    if (transition === 'idleNotification') {
      setOutputBlocks((prevBlocks) => {
        const transitionStartBlocks = getFirstMessagesFromTransition(transition);

        setTimeout(() => {
          setLoading(false);
        }, 5000);

        return [...prevBlocks, ...transitionStartBlocks, actionsBlock(transition, undefined, true)];
      });
    }
  };

  const updateInitialRoutine = () => {
    updateOutputs('initialRoutine', undefined, true);
  };

  const handlePlay = (audio: number) => {
    setOutputBlocks((prevBlocks) => {
      const newBlocks = prevBlocks.map((element, index) => {
        if (element.type === 'bot-sound' && index !== audio) {
          return { ...element, soundPause: true };
        } else if (element.type === 'bot-sound' && index === audio) {
          return { ...element, soundPause: false };
        }
        return element;
      });

      return [...newBlocks];
    });
  };

  const renderMessages = () => {
    const renderBlock = (index: number) => {
      const showJamila = index === 0 || outputBlocks[index - 1].type === 'user';
      return (
        <ChatBlockWrap key={index}>
          {outputBlocks[index].type === 'bot' ? (
            <ChatBlock className={`visible`}>
              <ChatAvatarBox>{showJamila && <Avatar sx={{ width: '44px', height: '44px', backgroundColor: '#9DCCEA', svg: { display: 'none' } }} />}</ChatAvatarBox>
              <ChatContentBoxAnimated>
                {showJamila && <span className="name">Textbuddy</span>}
                <Box dangerouslySetInnerHTML={{ __html: outputBlocks[index].text! }}></Box>
              </ChatContentBoxAnimated>
            </ChatBlock>
          ) : outputBlocks[index].type === 'user' ? (
            <>
              <DateBlock>{getFormattedTime()}</DateBlock>
              <ActionsBlock>
                <>
                  {outputBlocks[index].actions!.map((transition) => (
                    <>
                      <Box flexDirection={transition.time ? 'column' : 'row'} key={transition.transition}>
                        <Button
                          className={`btn-action ${transition.active ? 'active' : ''}`}
                          disabled={transition.active}
                          variant="outlined"
                          onClick={() => {
                            if (stateMachine.states[transition.transition].redirectLink) {
                              window.open(stateMachine.states[transition.transition].redirectLink!, '_blank');
                            }
                            if (stateMachine.states[transition.transition].component) {
                              setCurrentComponent(stateMachine.states[transition.transition].component!);
                              setInitialOpening(false);
                            }
                            if (transition.transition === 'closeApplication') {
                              navigate('/website');
                            } else if (transition.action) {
                              transition.action();
                            } else if (transition.transition === 'contactForm') {
                              handleOpenModal();
                            } else {
                              handleAction(stateMachine.states[transition.transition].apiCall!, transition.transition, outputBlocks[index].actions!);
                            }
                          }}
                        >
                          {transition.text}
                        </Button>
                        {transition.time && <DateBlock justifyContent={'flex-end'}>{transition.time}</DateBlock>}
                      </Box>
                    </>
                  ))}
                </>
              </ActionsBlock>
            </>
          ) : outputBlocks[index].type === 'bot-sound' ? (
            <>
              {audioMode && outputBlocks[index].soundVisible && (
                <ChatBlock className={`visible`}>
                  <ChatAvatarBox></ChatAvatarBox>
                  <ChatContentBoxAnimated>
                    <AudioPlayer src={outputBlocks[index].sound!} onPlay={() => handlePlay(index)} pause={outputBlocks[index].soundPause} />
                  </ChatContentBoxAnimated>
                </ChatBlock>
              )}
            </>
          ) : (
            <></>
          )}
        </ChatBlockWrap>
      );
    };

    return (
      <>
        {outputBlocks.slice(0, visibleBlocks + 1).map((_, index) => renderBlock(index))}
        {showTypingIcon && <TypingIndicator />}
      </>
    );
  };

  return (
    <>
      {currentComponent === 'chat' ? (
        <>
          {loading ? (
            <>
              {loadingDoc ? (
                <Loading onNavigateToTerms={() => setCurrentComponent('terms')} firstText={t('PreviewLetter')} doc={URL.createObjectURL(capturedImage!)} />
              ) : (
                <Loading
                  onNavigateToTerms={() => setCurrentComponent('terms')}
                  firstText={stateMachine.states[currentState].message.at(0) ?? 'undefined'}
                  secondText={stateMachine.states[currentState].message.at(1) ?? 'undefined'}
                />
              )}
            </>
          ) : (
            <Box sx={{ backgroundColor: '#a6acb0' }}>
              <Header onNavigateToTerms={() => setCurrentComponent('terms')} />
              <ChatBox>
                <ScrollableBlock ref={chatBoxRef}>{renderMessages()}</ScrollableBlock>
                <ImageUploader
                  onUploadSuccess={handleUploadSuccess}
                  onUploadFailure={handleUploadFailure}
                  onUploadCancel={handleUploadCancel}
                  onImageCapture={handleImageCapture}
                  ref={imageUploaderRef}
                />

                <CloseChatBox>
                  <Button onClick={() => navigate('/website')} variant="contained">
                    <img src={CloseCircleIcon} alt="Close Icon" />
                    <span className="btn-text">{t('Close')}</span>
                  </Button>
                </CloseChatBox>
                <ContactUsModal open={modalOpen} onClose={handleCloseModal} />
              </ChatBox>
            </Box>
          )}
        </>
      ) : currentComponent === 'languagePicker' ? (
        <LanguagePicker onNext={() => setCurrentComponent('terms')} />
      ) : (
        currentComponent === 'terms' && (
          <Terms
            onNext={() => {
              updateInitialRoutine();
              setCurrentComponent('chat');
            }}
          />
        )
      )}
    </>
  );
};

export default Chat;
