import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const isDesktop = window.innerWidth > 1024;

const resources = {
  nl: {
    translation: {
      'Terms of using translations': 'Voorwaarden voor het gebruik van vertalingen',
      'Translation disclaimer':
        'Dit document of deze website is voor uw gemak vertaald vanuit het Nederlands naar andere talen met behulp van vertaalsoftware. Machinevertalingen kunnen onjuistheden bevatten.',
      'Read more': 'Lees verder',
      'Read less': 'Minder lezen',
      'I have read the terms above and understand and agree before setting the default language to language':
        'Ik heb de bovenstaande voorwaarden gelezen en begrepen en ga akkoord voordat ik de standaardtaal op Nederlands instel',
      'Continue in language': 'Verder in het Nederlands',
      'Switch to Dutch (original)': 'Overschakelen naar Nederlands (origineel)',
      'Where would you like to be informed about?': 'Waar wil je meer informatie over?',
      'Everything about your retirement benefit plan': 'Alles over mijn pensioenregeling ',
      'At MijnPensioenschoonmaak you can see how much pension you have accrued.':
        'Op MijnPensioenschoonmaak ziet u hoeveel <1>pensioen</1> u heeft opgebouwd. Ook vindt u gemakkelijk informatie over uw pensioen.',
      'See what is arranged for you': 'Bekijk wat er voor mij is geregeld',
      'Translated by Google (no rights reserved)': 'Translated by Google (no rights reserved)',
      'When do I do what?': 'Wanneer doe ik wat?',
      'Is there a change in your work, relationship or are you moving?':
        'Verandert er iets in uw werk, relatie of gaat u verhuizen? Bekijk wat dat betekent voor uw <1>pensioen</1>. En wat u misschien moet regelen.',
      'My circumstances are changing': 'Mijn situatie verandert',
      'How do I start?': 'Hoe begin ik?',
      'MijnPensioenschoonmaak contains information about your pension.':
        'Op MijnPensioenschoonmaak staat informatie over uw <1>pensioen</1>. U ziet bijvoorbeeld hoeveel pensioen u heeft. En welke gegevens wij van u hebben.',
      'View my personal information': 'Bekijk mijn gegevens',
      'How much pension have i accreud?': 'Hoeveel pensioen heb ik?',
      'Every month, part of your salary goes to your pension pot.':
        'Elke maand gaat een deel van uw salaris naar uw pensioenpotje. Uw werkgever spaart ook mee. U hoeft daar niets voor te doen. Het pensioenfonds zorgt voor uw geld.',
      'View your accrued pension': 'Bekijk de hoogte van uw pensioen',
      'What does my Pensionfund do for me?': 'Wat is er geregeld?',
      Greeting: 'Hallo, mijn naam is Textbuddy. Ik zal nu je brief verwerken, zodat we wat veranderingen kunnen aanbrengen om je te helpen de informatie wat beter te begrijpen.',
      LetterProcessed: 'Je brief is verwerkt, ik zal de informatie nu voor je vertalen naar het Nederlands.',
      GreetingGlossary: 'Hoi, mijn naam is Textbuddy. Ik zal de term voor je uitleggen.',
      GlossaryReceived: 'Ik heb een uitleg voor de term gevonden. Ik zal de informatie nu voor je naar het Nederlands vertalen.',
      Close: 'Sluit',
      EntireLetter: 'Dit is de hele brief in het Nederlands.',
      SimplifiedLetter: 'Ik heb de vereenvoudigde brief voor je vertaald. Je kunt ook een van de andere opties hieronder kiezen.',
      DisplayEntireLetterButton: 'Toon hele brief',
      DisplaySimplifyLetterButton: 'Toon vereenvoudigde brief',
      ReadAloud: 'Klik op de afspeelknoppen in de bovenstaande berichten en ik zal de tekst voorlezen. Voortaan tonen alle nieuwe berichten ook deze optie.',
      ReadAloudButton: 'Tekst voorlezen',
      StopReadAloud: 'Oké, ik zal stoppen met het hardop voorlezen van nieuwe tekst voor jou.',
      StopReadAloudButton: 'Stop met voorlezen',
      'Set language': 'Taal instellen',
      'Switch language': 'Taal wisselen',
      AnotherQuestion: 'Het lijkt erop dat je wat hulp kunt gebruiken. Wat kan ik voor je doen?',
      DisplayAnotherQuestionButton: 'Ik heb nog een vraag',
      DisplayContactFormButton: 'Contact',
      DisplayHelpWithLetterButton: 'Help me met een brief',
      HelpWithLetter: isDesktop ? 'Ik ga je helpen een brief beter te begrijpen. Ben je klaar om een bestand te uploaden?' : "Ik ga je helpen een brief beter te begrijpen. Gebruik de camera van je apparaat om een foto te maken. Ben je klaar om een foto te maken?",
      HelpWithLetterYes: isDesktop ? 'Het bestand upload scherm zal nu openen.' : 'De camera-app zal nu openen om de foto te nemen.',
      DisplayHelpWithLetterYesButton: isDesktop ? 'Ja, bestand uploaden' : 'Ja, foto maken',
      DisplayHelpWithLetterNoButton: isDesktop ? 'Nee, geen bestand uploaden' : 'Nee, geen foto maken',
      OpenCamera: isDesktop ? 'Upload bestand' : 'Open camera',
      Menu: 'Menu',
      ChangeLanguage: 'Taal wijzigen',
      'Uw Pension': 'Uw Pension',
      'Plan uw pensioen': 'Pan uw pensioen',
      'Uw situatie verandert': 'Uw situatie verandert',
      'Over ons': 'Over ons',
      Contact: 'Contact',
      Inloggen: 'Inloggen',
      GreetingMinimal: 'Hallo, mijn naam is Textbuddy.',
      DisplayCloseApplicationButton: 'Sluit toepassing',
      'Need help?': 'Hulp nodig?',
      ProcessLetter: 'Ik zal nu je brief verwerken, zodat we wat veranderingen kunnen aanbrengen om je te helpen de informatie wat beter te begrijpen.',
      PreviewLetter: 'Dit is een voorbeeld van je brief. Wacht alsjeblieft terwijl we het voor je verwerken.',
      ChatWithUsTitle: 'Chat met ons',
      ChatWithUsText: 'Stel uw vraag via chat. Op werkdagen tussen 08.30 en 17.00 uur.',
      ChatWithUsButton: 'Start een chat',
      EmailUsTitle: 'Mail ons',
      EmailUsText: 'Voor uitgebreide vragen stuurt u ons een e-mail.',
      EmailUsButton: 'Stuur bericht',
      CallUsTitle: 'Bel ons',
      CallUsText: 'U kunt ons bellen op telefoonnummer 020 583 51 00.',
      Welcome1: 'Welkom bij Textbuddy. Hier kun je hulp krijgen zodat je pensioeninformatie nog beter kunt begrijpen.',
      Welcome2: 'Je kunt bijvoorbeeld een taal kiezen zodat informatie voor je  vertaald kan worden.',
      ChooseLanguageButton: 'Kies een taal',
      SuccessfullyChangingLanguage: 'Prima! Uw taal is nu ingesteld op Nederlands.',
    },
  },
  en: {
    translation: {
      'Terms of using translations': 'Terms of using translations',
      'Translation disclaimer':
        'This document or this website has been translated for your convenience from Dutch into other languages using translation software. Machine-generated translations may contain inaccuracies.',
      'Read more': 'Read more',
      'Read less': 'Read less',
      'I have read the terms above and understand and agree before setting the default language to language':
        'I have read the terms above and understand and agree before setting the default language to English',
      'Continue in language': 'Continue in English',
      'Switch to Dutch (original)': 'Switch to Dutch (original)',
      'Where would you like to be informed about?': 'Where would you like to be informed about?',
      'Everything about your retirement benefit plan': 'Everything about your retirement benefit plan',
      'At MijnPensioenschoonmaak you can see how much pension you have accrued.':
        'At MijnPensioenschoonmaak you can see how much <1>pension</1> you have accrued. In addition you will find simple information about your pension.',
      'See what is arranged for you': 'See what is arranged for you',
      'Translated by Google (no rights reserved)': 'Translated by Google (no rights reserved)',
      'When do I do what?': 'When do I do what?',
      'Is there a change in your work, relationship or are you moving?':
        'Is there a change in your work, relationship or are you moving? See what the effects are for your <1>pension</1>. And what you may need to arrange.',
      'My circumstances are changing': 'My circumstances are changing',
      'How do I start?': 'How do I start?',
      'MijnPensioenschoonmaak contains information about your pension.':
        'MijnPensioenschoonmaak contains information about your pension. For example, you can see how much pension you have accrued. And what personal information we have about you.',
      'View my personal information': 'View my personal information',
      'How much pension have i accreud?': 'How much pension have i accreud?',
      'Every month, part of your salary goes to your pension pot.':
        "Every month, part of your salary goes to your pension pot. In addition your employer also deposits a part. You don't have to do anything for that. The pension fund takes care of your money.",
      'View your accrued pension': 'View your accrued pension',
      'What does my Pensionfund do for me?': 'What does my Pensionfund do for me?',
      Greeting: 'Hey, my name is Textbuddy. I will now process your letter so we can make some changes to help you understand the information a bit better.',
      LetterProcessed: 'Your letter has been processed, I will now translate the information for you to English.',
      GreetingGlossary: 'Hey, my name is Textbuddy. I will explain the term for you.',
      GlossaryReceived: 'I found an explanation for the term. I will now translate the information for you to English.',
      Close: 'Close',
      EntireLetter: 'This is the entire letter translated in English.',
      SimplifiedLetter: 'I have translated the simplified letter for you. You can also choose one of the other options below.',
      DisplayEntireLetterButton: 'Display entire letter',
      DisplaySimplifyLetterButton: 'Simplify this letter',
      ReadAloud: "You can click on the play buttons in the messages above and I'll read the text aloud. From now on all new messages will also show this option.",
      ReadAloudButton: 'Read text aloud',
      StopReadAloud: 'Ok, I will stop reading new text aloud for you.',
      StopReadAloudButton: 'Stop reading aloud',
      'Set language': 'Set language',
      'Switch language': 'Switch language',
      AnotherQuestion: 'It seems like you could use some help. What can I do to help you out?',
      DisplayAnotherQuestionButton: 'I have another question',
      DisplayContactFormButton: 'Contact',
      DisplayHelpWithLetterButton: 'Help me with a letter',
      HelpWithLetter: isDesktop
        ? 'I’m going to help you understand a letter better. Are you ready to upload a file?'
        : 'I’m going to help you understand a letter better. Use your device’s camera to take a picture. Are you ready to take a picture?',
      HelpWithLetterYes: isDesktop ? 'The file upload interface will now open.' : 'The camera app will now open to take the picture.',
      DisplayHelpWithLetterYesButton: isDesktop ? 'Yes, upload file' : 'Yes, take a picture',
      DisplayHelpWithLetterNoButton: isDesktop ? 'No, do not upload file' : 'No, do not take a picture',
      OpenCamera: isDesktop ? 'Upload File' : 'Open Camera',
      Menu: 'Menu',
      ChangeLanguage: 'Change language',
      'Uw Pension': 'Your Pension',
      'Plan uw pensioen': 'Plan your pension',
      'Uw situatie verandert': 'Your situation changes',
      'Over ons': 'About us',
      Contact: 'Contact',
      Inloggen: 'Login',
      GreetingMinimal: 'Hey, my name is Textbuddy.',
      DisplayCloseApplicationButton: 'Close Application',
      'Need help?': 'Need help?',
      ProcessLetter: 'I will now process your letter so we can make some changes to help you understand the information a bit better.',
      PreviewLetter: 'This is a preview of your letter. Please wait while we are processing it for you.',
      ChatWithUsTitle: 'Chat with us',
      ChatWithUsText: 'Ask your question via chat. On weekdays between 08:30 and 17:00.',
      ChatWithUsButton: 'Start a chat',
      EmailUsTitle: 'Email us',
      EmailUsText: 'For extensive questions, please send us an email.',
      EmailUsButton: 'Send a message',
      CallUsTitle: 'Call us',
      CallUsText: 'You can call us at the telephone number 020 583 51 00.',
      Welcome1: 'Welcome to Textbuddy. Here you can get assistance to better understand your pension information.',
      Welcome2: 'For instance, you can choose a language so that information can be translated for you.',
      ChooseLanguageButton: 'Choose a language',
      SuccessfullyChangingLanguage: 'Great! Your language is now set to English.',
    },
  },
  de: {
    translation: {
      'Terms of using translations': 'Nutzungsbedingungen für Übersetzungen',
      'Translation disclaimer':
        'Um Ihnen die Arbeit zu erleichtern, wurde dieses Dokument bzw. diese Website mit Hilfe von Übersetzungssoftware aus dem Niederländischen in andere Sprachen übersetzt. Maschinelle Übersetzungen können Ungenauigkeiten enthalten.',
      'Read more': 'Mehr lesen',
      'Read less': 'Weniger lesen',
      'I have read the terms above and understand and agree before setting the default language to language':
        'Ich habe die oben genannten Hinweise gelesen, verstanden und akzeptiere sie, bevor ich die Standardsprache auf Deutsch setze',
      'Continue in language': 'Fortfahren auf Deutsch',
      'Switch to Dutch (original)': 'Zu Niederländisch (Original) wechseln',
      'Where would you like to be informed about?': 'Worüber möchten Sie informiert werden?',
      'Everything about your retirement benefit plan': 'Alles über Ihren Rentenvorteilsplan',
      'At MijnPensioenschoonmaak you can see how much pension you have accrued.':
        'Bei MijnPensioenschoonmaak können Sie sehen, wie viel <1>Rente</1> Sie angesammelt haben. Zusätzlich finden Sie einfache Informationen über Ihre Rente.',
      'See what is arranged for you': 'Sehen Sie, was für Sie arrangiert wurde',
      'Translated by Google (no rights reserved)': 'Übersetzt von Google (keine Rechte vorbehalten)',
      'When do I do what?': 'Wann mache ich was?',
      'Is there a change in your work, relationship or are you moving?':
        'Gibt es eine Veränderung in Ihrer Arbeit, Beziehung, oder ziehen Sie um? Sehen Sie, welche Auswirkungen dies auf Ihre <1>Rente</1> hat. Und was Sie möglicherweise arrangieren müssen.',
      'My circumstances are changing': 'Meine Umstände ändern sich',
      'How do I start?': 'Wie fange ich an?',
      'MijnPensioenschoonmaak contains information about your pension.':
        'MijnPensioenschoonmaak enthält Informationen über Ihre Rente. Zum Beispiel können Sie sehen, wie viel Rente Sie angesammelt haben. Und welche persönlichen Informationen wir über Sie haben.',
      'View my personal information': 'Meine persönlichen Informationen ansehen',
      'How much pension have i accreud?': 'Wie viel Rente habe ich angesammelt?',
      'Every month, part of your salary goes to your pension pot.':
        'Jeden Monat geht ein Teil Ihres Gehalts in Ihren Rententopf. Zusätzlich zahlt auch Ihr Arbeitgeber einen Teil ein. Dafür müssen Sie nichts tun. Der Rentenfonds kümmert sich um Ihr Geld.',
      'View your accrued pension': 'Sehen Sie Ihre angesammelte Rente an',
      'What does my Pensionfund do for me?': 'Was macht mein Rentenfonds für mich?',
      Greeting: 'Hallo, mein Name ist Textbuddy. Ich werde jetzt Ihren Brief bearbeiten, sodass Sie die enthaltenen Informationen besser verstehen können.',
      LetterProcessed: 'Ihr Brief wurde bearbeitet, ich werde die Informationen nun für Sie ins Deutsche übersetzen.',
      GreetingGlossary: 'Hallo, mein Name ist Textbuddy. Ich werde Ihnen den Begriff erklären.',
      GlossaryReceived: 'Ich habe eine Erklärung für den Begriff gefunden. Ich werde nun die Informationen für Sie ins Deutsche übersetzen.',
      Close: 'Schließen',
      EntireLetter: 'Dies ist der gesamte Brief ins Deutsche übersetzt.',
      SimplifiedLetter: 'Ich habe das vereinfachte Schreiben für Sie übersetzt. Sie können auch eine der anderen Optionen unten wählen.',
      DisplayEntireLetterButton: 'Gesamten Brief anzeigen',
      DisplaySimplifyLetterButton: 'Vereinfachten Brief anzeigen',
      ReadAloud: 'Sie können auf die Wiedergabeschaltflächen in den oben stehenden Nachrichten klicken, und ich werde den Text vorlesen. Ab sofort zeigt auch jede neue Nachricht diese Option an.',
      ReadAloudButton: 'Text vorlesen',
      StopReadAloud: 'Ok, ich werde aufhören, neuen Text für dich vorzulesen.',
      StopReadAloudButton: 'Vorlesen beenden',
      'Set language': 'Sprache einstellen',
      'Switch language': 'Sprache wechseln',
      AnotherQuestion: 'Es sieht so aus, als ob du etwas Hilfe gebrauchen könntest. Was kann ich tun, um dir zu helfen?',
      DisplayAnotherQuestionButton: 'Ich habe eine andere Frage',
      DisplayContactFormButton: 'Kontakt',
      DisplayHelpWithLetterButton: 'Hilf mir mit einem Brief',
      HelpWithLetter: isDesktop
        ? 'Ich werde dir helfen, einen Brief besser zu verstehen. Bist du bereit, eine Datei hochzuladen?'
        : 'Ich werde dir helfen, einen Brief besser zu verstehen. Verwende die Kamera deines Geräts, um ein Bild aufzunehmen. Bist du bereit, ein Bild aufzunehmen?',
      HelpWithLetterYes: isDesktop ? 'Die Datei-Upload-Schnittstelle wird jetzt geöffnet.' : 'Die Kamera-App wird jetzt geöffnet, um das Bild aufzunehmen.',
      DisplayHelpWithLetterYesButton: isDesktop ? 'Ja, Datei hochladen' : 'Ja, ein Bild aufnehmen',
      DisplayHelpWithLetterNoButton: isDesktop ? 'Nein, keine Datei hochladen' : 'Nein, kein Bild aufnehmen',
      OpenCamera: isDesktop ? 'Datei hochladen' : 'Kamera öffnen',
      Menu: 'Menü',
      ChangeLanguage: 'Sprache ändern',
      'Uw Pension': 'Ihre Rente',
      'Plan uw pensioen': 'Planen Sie Ihre Rente',
      'Uw situatie verandert': 'Ihre Situation ändert sich',
      'Over ons': 'Über uns',
      Contact: 'Kontakt',
      Inloggen: 'Einloggen',
      GreetingMinimal: 'Hallo, mein Name ist Textbuddy.',
      DisplayCloseApplicationButton: 'Anwendung schließen',
      'Need help?': 'Brauchen Sie Hilfe?',
      ProcessLetter: 'Ich werde jetzt Ihren Brief bearbeiten, damit wir einige Änderungen vornehmen können, damit Sie die Informationen besser verstehen können.',
      PreviewLetter: 'Dies ist eine Vorschau Ihres Briefes. Bitte warten Sie, während wir es für Sie verarbeiten.',
      ChatWithUsTitle: 'Chatten Sie mit uns',
      ChatWithUsText: 'Stellen Sie Ihre Frage über den Chat. Werktags zwischen 08:30 und 17:00 Uhr.',
      ChatWithUsButton: 'Einen Chat starten',
      EmailUsTitle: 'Schreiben Sie uns eine E-Mail',
      EmailUsText: 'Für umfangreiche Fragen senden Sie uns bitte eine E-Mail.',
      EmailUsButton: 'Eine Nachricht senden',
      CallUsTitle: 'Rufen Sie uns an',
      CallUsText: 'Sie können uns unter der Telefonnummer 020 583 51 00 anrufen.',
      SuccessfullyChangingLanguage: 'Großartig! Ihre Sprache ist jetzt auf Deutsch eingestellt.',
      Welcome1: 'Willkommen bei Textbuddy. Hier kannst du Hilfe erhalten, um deine Renteninformationen besser zu verstehen.',
      Welcome2: 'Du kannst beispielsweise eine Sprache wählen, damit Informationen für dich übersetzt werden können.',
      ChooseLanguageButton: 'Wähle eine Sprache',
    },
  },
  uk: {
    translation: {
      'Terms of using translations': 'Умови використання перекладів',
      'Translation disclaimer':
        'Цей документ або веб-сайт було перекладено для вашої зручності з нідерландської на інші мови за допомогою програмного перекладу. Переклади, створені машиною, можуть містити помилки.',
      'Read more': 'Читати більше',
      'Read less': 'Читати менше',
      'I have read the terms above and understand and agree before setting the default language to language':
        'Я прочитав вищезазначені умови, розумію їх і згоден, перш ніж встановити українську мову як мову за замовчуванням.',
      'Continue in language': 'Продовжити українською',
      'Switch to Dutch (original)': 'Перейти на нідерландську (оригінал)',
      Greeting: 'Привіт, мене звати Джаміля. Зараз я опрацюю ваш лист, щоб ми могли внести деякі зміни, які допоможуть вам краще зрозуміти інформацію.',
      LetterProcessed: 'Ваш лист опрацьовано, зараз я переведу інформацію для вас на українську мову.',
      GreetingGlossary: 'Привіт, мене звати Джаміла. Я поясню вам цей термін.',
      GlossaryReceived: 'Я знайшла пояснення до терміну. Тепер я перекладу інформацію для вас українською мовою.',
      Close: 'Закрити',
      EntireLetter: 'Ось весь лист у перекладі на українську мову.',
      SimplifiedLetter: 'Я переклав для вас спрощений варіант листа. Ви також можете вибрати один з інших варіантів нижче.',
      DisplayEntireLetterButton: 'Показати весь лист',
      DisplaySimplifyLetterButton: 'Показати спрощений лист',
      ReadAloud: 'Ви можете натискати на кнопки відтворення у повідомленнях вище, і я прочитаю текст. Відтепер всі нові повідомлення також відображатимуть цю опцію.',
      ReadAloudButton: 'Прочитати текст вголос',
      'Set language': 'Встановити мову',
      'Switch language': 'Змінити мову',
      StopReadAloud: 'Добре, я перестану читати новий текст вголос для вас.',
      StopReadAloudButton: 'Зупинити читання вголос',
      AnotherQuestion: 'Здається, вам може знадобитися допомога. Як я можу вам допомогти?',
      DisplayAnotherQuestionButton: 'У мене є ще одне питання',
      DisplayContactFormButton: "Зв'язатися",
      DisplayHelpWithLetterButton: 'Допоможіть мені з листом',
      HelpWithLetter: isDesktop
        ? 'Я допоможу вам краще зрозуміти лист. Готові завантажити файл?'
        : 'Я допоможу вам краще зрозуміти лист. Використовуйте камеру вашого пристрою, щоб зробити фото. Готові зробити фото?',
      HelpWithLetterYes: isDesktop ? 'Інтерфейс завантаження файлу зараз відкриється.' : 'Зараз відкриється програма камери, щоб зробити фото.',
      DisplayHelpWithLetterYesButton: isDesktop ? 'Так, завантажити файл' : 'Так, зробити фото',
      DisplayHelpWithLetterNoButton: isDesktop ? 'Ні, не завантажувати файл' : 'Ні, не робити фото',
      OpenCamera: isDesktop ? 'Завантажити файл' : 'Відкрити камеру',
      Menu: 'Меню',
      ChangeLanguage: 'Змінити мову',
      'Uw Pension': 'Ваша Пенсія',
      'Plan uw pensioen': 'Плануйте вашу пенсію',
      'Uw situatie verandert': 'Ваша ситуація змінюється',
      'Over ons': 'Про нас',
      Contact: 'Контакт',
      Inloggen: 'Увійти',
      GreetingMinimal: 'Привіт, мене звати Джаміла.',
      DisplayCloseApplicationButton: 'Закрити додаток',
      'Need help?': 'Потрібна допомога?',
      ProcessLetter: 'Тепер я оброблю ваш лист, щоб ми могли внести деякі зміни, які допоможуть вам краще зрозуміти інформацію.',
      PreviewLetter: 'Це попередній перегляд вашого листа. Будь ласка, зачекайте, поки ми обробляємо його для вас.',
      ChatWithUsTitle: 'Чат з нами',
      ChatWithUsText: 'Задайте своє питання через чат. У робочі дні з 08:30 до 17:00.',
      ChatWithUsButton: 'Розпочати чат',
      EmailUsTitle: 'Напишіть нам листа',
      EmailUsText: 'Для розгорнутих питань, будь ласка, надішліть нам електронного листа.',
      EmailUsButton: 'Надіслати повідомлення',
      CallUsTitle: 'Подзвоніть нам',
      CallUsText: 'Ви можете зателефонувати нам за телефоном 020 583 51 00.',
      Welcome1: 'Ласкаво просимо до Textbuddy. Тут ви можете отримати допомогу для кращого розуміння інформації про вашу пенсію.',
      Welcome2: 'Наприклад, ви можете вибрати мову, щоб інформацію можна було перекласти для вас.',
      ChooseLanguageButton: 'Виберіть мову',
      SuccessfullyChangingLanguage: 'Прекрасно! Ваша мова тепер встановлена на українську мову.',
    },
  },
  pl: {
    translation: {
      'Terms of using translations': 'Warunki korzystania z tłumaczeń',
      'Translation disclaimer':
        'Ten dokument lub ta strona internetowa zostały przetłumaczone dla Twojej wygody z języka niderlandzkiego na inne języki za pomocą oprogramowania tłumaczącego. Tłumaczenia wygenerowane przez programy komputerowe mogą zawierać nieścisłości.',
      'Read more': 'Czytaj więcej',
      'Read less': 'Czytaj mniej',
      'I have read the terms above and understand and agree before setting the default language to language':
        'Przeczytałem powyższe warunki, rozumiem je i akceptuję, zanim ustawię język polski jako domyślny',
      'Continue in language': 'Kontynuuj po polsku',
      'Switch to Dutch (original)': 'Przełącz na niderlandzki (oryginalny)',
      Greeting: 'Hej, nazywam się Textbuddy. Przetworzę teraz twój list, abyśmy mogli wprowadzić pewne zmiany, które pomogą ci lepiej zrozumieć informacje.',
      LetterProcessed: 'Twój list został przetworzony, teraz przetłumaczę informacje na język polski.',
      GreetingGlossary: 'Cześć, mam na imię Textbuddy. Wyjaśnię dla Ciebie ten termin.',
      GlossaryReceived: 'Znalazłam wyjaśnienie terminu. Teraz przetłumaczę te informacje dla Ciebie na język polski.',
      Close: 'Zamknij',
      EntireLetter: 'Oto cały list przetłumaczony na język polski.',
      SimplifiedLetter: 'Przetłumaczyłem dla Ciebie uproszczony list. Możesz również wybrać jedną z innych opcji poniżej.',
      DisplayEntireLetterButton: 'Pokaż cały list',
      DisplaySimplifyLetterButton: 'Pokaż uproszczony list',
      ReadAloud: 'Możesz klikać na przyciski odtwarzania w powyższych wiadomościach, a ja przeczytam tekst na głos. Od teraz wszystkie nowe wiadomości również pokażą tę opcję.',
      ReadAloudButton: 'Przeczytaj tekst na głos',
      'Set language': 'Ustaw język',
      'Switch language': 'Zmień język',
      StopReadAloud: 'Dobrze, przestanę czytać nowy tekst na głos dla Ciebie.',
      StopReadAloudButton: 'Przestań czytać na głos',
      AnotherQuestion: 'Wygląda na to, że możesz potrzebować pomocy. Jak mogę ci pomóc?',
      DisplayAnotherQuestionButton: 'Mam inne pytanie',
      DisplayContactFormButton: 'Kontakt',
      DisplayHelpWithLetterButton: 'Pomóż mi z listem',
      HelpWithLetter: isDesktop
        ? 'Pomogę ci lepiej zrozumieć list. Czy jesteś gotowy, aby przesłać plik?'
        : 'Pomogę ci lepiej zrozumieć list. Użyj aparatu swojego urządzenia, aby zrobić zdjęcie. Czy jesteś gotowy, aby zrobić zdjęcie?',
      HelpWithLetterYes: isDesktop ? 'Interfejs przesyłania pliku zostanie teraz otwarty.' : 'Teraz zostanie otwarta aplikacja aparatu, aby zrobić zdjęcie.',
      DisplayHelpWithLetterYesButton: isDesktop ? 'Tak, przesłać plik' : 'Tak, zrobić zdjęcie',
      DisplayHelpWithLetterNoButton: isDesktop ? 'Nie, nie przesyłaj pliku' : 'Nie, nie rób zdjęcia',
      OpenCamera: isDesktop ? 'Prześlij plik' : 'Otwórz aparat',
      Menu: 'Menu',
      ChangeLanguage: 'Zmień język',
      'Uw Pension': 'Twoje Emerytura',
      'Plan uw pensioen': 'Zaplanuj swoją emeryturę',
      'Uw situatie verandert': 'Twoja sytuacja się zmienia',
      'Over ons': 'O nas',
      Contact: 'Kontakt',
      Inloggen: 'Zaloguj się',
      GreetingMinimal: 'Cześć, mam na imię Textbuddy.',
      DisplayCloseApplicationButton: 'Zamknij aplikację',
      'Need help?': 'Potrzebujesz pomocy?',
      ProcessLetter: 'Teraz przetworzę twój list, abyśmy mogli wprowadzić pewne zmiany, które pomogą ci lepiej zrozumieć informacje.',
      PreviewLetter: 'To jest podgląd twojego listu. Proszę poczekaj, podczas gdy go przetwarzamy dla ciebie.',
      ChatWithUsTitle: 'Porozmawiaj z nami',
      ChatWithUsText: 'Zadaj pytanie za pośrednictwem czatu. W dni robocze między 08:30 a 17:00.',
      ChatWithUsButton: 'Rozpocznij czat',
      EmailUsTitle: 'Napisz do nas e-mail',
      EmailUsText: 'Na rozległe pytania prosimy o przesłanie nam e-maila.',
      EmailUsButton: 'Wyślij wiadomość',
      CallUsTitle: 'Zadzwoń do nas',
      CallUsText: 'Możesz do nas zadzwonić pod numer telefonu 020 583 51 00.',
      Welcome1: 'Witaj w Textbuddy. Tutaj możesz uzyskać pomoc, aby lepiej zrozumieć informacje o swojej emeryturze.',
      Welcome2: 'Na przykład możesz wybrać język, aby informacje mogły być dla ciebie przetłumaczone.',
      ChooseLanguageButton: 'Wybierz język',
      SuccessfullyChangingLanguage: 'Świetnie! Teraz Twoj język został ustawiony na polski.',
    },
  },
  es: {
    translation: {
      'Terms of using translations': 'Términos de uso de las traducciones',
      'Translation disclaimer':
        'Este documento o sitio web ha sido traducido para su comodidad del neerlandés a otros idiomas utilizando software de traducción. Las traducciones generadas por máquina pueden contener inexactitudes.',
      'Read more': 'Leer más',
      'Read less': 'Leer menos',
      'I have read the terms above and understand and agree before setting the default language to language':
        'He leído los términos anteriores, los entiendo y acepto antes de establecer el español como idioma predeterminado.',
      'Continue in language': 'Continuar en español',
      'Switch to Dutch (original)': 'Cambiar a holandés (original)',
      Greeting: 'Hola, me llamo Textbuddy. Ahora voy a procesar tu carta para que podamos hacer algunos cambios que te ayuden a entender un poco mejor la información.',
      LetterProcessed: 'Su carta ha sido procesada, ahora le traduciré la información al español.',
      GreetingGlossary: 'Hola, mi nombre es Textbuddy. Te explicaré el término.',
      GlossaryReceived: 'He encontrado una explicación para el término. Ahora traduciré la información al español para ti.',
      Close: 'Cerrar',
      EntireLetter: 'Esta es la carta completa traducida al español.',
      SimplifiedLetter: 'Le he traducido la carta simplificada. También puede elegir una de las otras opciones que aparecen a continuación.',
      DisplayEntireLetterButton: 'Mostrar toda la carta',
      DisplaySimplifyLetterButton: 'Mostrar carta simplificada',
      ReadAloud: 'Puedes hacer clic en los botones de reproducción en los mensajes arriba y yo leeré el texto en voz alta. A partir de ahora, todos los mensajes nuevos también mostrarán esta opción.',
      ReadAloudButton: 'Leer el texto en voz alta',
      'Set language': 'Establecer idioma',
      'Switch language': 'Cambiar idioma',
      StopReadAloud: 'Ok, dejaré de leer en voz alta el nuevo texto para ti.',
      StopReadAloudButton: 'Dejar de leer en voz alta',
      AnotherQuestion: 'Parece que podrías necesitar ayuda. ¿Qué puedo hacer para ayudarte?',
      DisplayAnotherQuestionButton: 'Tengo otra pregunta',
      DisplayContactFormButton: 'Contacto',
      DisplayHelpWithLetterButton: 'Ayúdame con una carta',
      HelpWithLetter: isDesktop
        ? 'Voy a ayudarte a entender mejor una carta. ¿Estás listo para subir un archivo?'
        : 'Voy a ayudarte a entender mejor una carta. Usa la cámara de tu dispositivo para tomar una foto. ¿Estás listo para tomar una foto?',
      HelpWithLetterYes: isDesktop ? 'Se abrirá ahora la interfaz de carga de archivos.' : 'Se abrirá ahora la aplicación de cámara para tomar la foto.',
      DisplayHelpWithLetterYesButton: isDesktop ? 'Sí, subir archivo' : 'Sí, tomar foto',
      DisplayHelpWithLetterNoButton: isDesktop ? 'No, no subir archivo' : 'No, no tomar foto',
      OpenCamera: isDesktop ? 'Subir archivo' : 'Abrir cámara',
      Menu: 'Menú',
      ChangeLanguage: 'Cambiar idioma',
      'Uw Pension': 'Su Pensión',
      'Plan uw pensioen': 'Planifique su pensión',
      'Uw situatie verandert': 'Su situación cambia',
      'Over ons': 'Sobre nosotros',
      Contact: 'Contacto',
      Inloggen: 'Iniciar sesión',
      GreetingMinimal: 'Hola, mi nombre es Textbuddy.',
      DisplayCloseApplicationButton: 'Cerrar aplicación',
      'Need help?': '¿Necesitas ayuda?',
      ProcessLetter: 'Ahora procesaré tu carta para que podamos hacer algunos cambios que te ayuden a entender un poco mejor la información.',
      PreviewLetter: 'Esta es una vista previa de tu carta. Por favor, espera mientras la procesamos para ti.',
      ChatWithUsTitle: 'Chatea con nosotros',
      ChatWithUsText: 'Haz tu pregunta a través del chat. En días laborables de 08:30 a 17:00.',
      ChatWithUsButton: 'Iniciar chat',
      EmailUsTitle: 'Envíanos un correo electrónico',
      EmailUsText: 'Para preguntas extensas, por favor envíanos un correo electrónico.',
      EmailUsButton: 'Enviar un mensaje',
      CallUsTitle: 'Llámanos',
      CallUsText: 'Puedes llamarnos al número de teléfono 020 583 51 00.',
      Welcome1: 'Bienvenido a Textbuddy. Aquí puedes obtener ayuda para entender mejor la información de tu pensión.',
      Welcome2: 'Por ejemplo, puedes elegir un idioma para que la información se pueda traducir para ti.',
      ChooseLanguageButton: 'Elige un idioma',
      SuccessfullyChangingLanguage: '¡Genial! Tu idioma ahora está configurado en español.',
    },
  },
  ar: {
    translation: {
      'Terms of using translations': 'شروط استخدام الترجمات',
      'Translation disclaimer': 'تمت ترجمة هذا المستند أو هذا الموقع الإلكتروني من اللغة الهولندية إلى لغات أخرى باستخدام برنامج الترجمة. قد تحتوي الترجمات التي تنتجها الآلة على عدم دقة.',
      'Read more': 'اقرأ المزيد',
      'Read less': 'اقرأ أقل',
      'I have read the terms above and understand and agree before setting the default language to language': 'لقد قرأت الشروط أعلاه وفهمتها ووافقت عليها قبل تعيين اللغة العربية كلغة افتراضية',
      'Continue in language': 'استمر باللغة العربية',
      'Switch to Dutch (original)': 'التحول إلى الهولندية (الأصلية)',
      Greeting: 'مرحبًا، اسمي جميلة. سأقوم الآن بمعالجة رسالتك حتى نتمكن من إجراء بعض التغييرات لمساعدتك في فهم المعلومات بشكل أفضل.',
      LetterProcessed: 'تم معالجة رسالتك، سأقوم الآن بترجمة المعلومات لك إلى اللغة العربية.',
      GreetingGlossary: 'مرحبًا، اسمي جميلة. سأشرح المصطلح لك.',
      GlossaryReceived: 'لقد وجدت تفسيرا لهذا المصطلح. سأقوم الآن بترجمة المعلومات إلى اللغة العربية لك.',
      Close: 'إغلاق',
      EntireLetter: 'هذه هي الرسالة بأكملها مترجمة إلى العربية.',
      SimplifiedLetter: 'لقد قمت بترجمة الرسالة المبسطة لك. يمكنك أيضًا اختيار إحدى الخيارات الأخرى أدناه.',
      DisplayEntireLetterButton: 'عرض الرسالة بأكملها',
      DisplaySimplifyLetterButton: 'عرض الرسالة المبسطة',
      ReadAloud: 'يمكنك النقر على أزرار التشغيل في الرسائل أعلاه وسأقرأ النص بصوت عال. اعتبارًا من الآن، ستظهر جميع الرسائل الجديدة أيضًا هذا الخيار.',
      ReadAloudButton: 'اقرأ النص بصوت عال',
      StopReadAloud: 'حسناً، سأتوقف عن قراءة النص الجديد بصوت عالٍ لك.',
      StopReadAloudButton: 'توقف عن القراءة بصوت عالٍ',
      'Set language': 'تعيين اللغة',
      'Switch language': 'تبديل اللغة',
      AnotherQuestion: 'يبدو أنك بحاجة إلى بعض المساعدة. كيف يمكنني مساعدتك؟',
      DisplayAnotherQuestionButton: 'لدي سؤال آخر',
      DisplayContactFormButton: 'آ تريد الاتصال بنا؟ اضغط هنا',
      DisplayHelpWithLetterButton: 'ساعدني في كتابة رسالة',
      HelpWithLetter: isDesktop ? 'سأساعدك في فهم الرسالة بشكل أفضل. هل أنت مستعد لتحميل ملف؟' : "سأساعدك في فهم الرسالة بشكل أفضل. استخدم كاميرا جهازك لالتقاط صورة. هل أنت مستعد لالتقاط صورة؟",
      HelpWithLetterYes: isDesktop ? 'واجهة تحميل الملف ستفتح الآن.' : 'سيتم فتح تطبيق الكاميرا الآن لالتقاط الصورة.',
      DisplayHelpWithLetterYesButton: isDesktop ? 'نعم، تحميل ملف' : 'نعم، التقاط صورة',
      DisplayHelpWithLetterNoButton: isDesktop ? 'لا، لا تحميل ملف' : 'لا، لا التقاط صورة',
      OpenCamera: isDesktop ? 'تحميل ملف' : 'فتح الكاميرا',
      Menu: 'القائمة',
      ChangeLanguage: 'تغيير اللغة',
      'Your Pension': 'معاشك التقاعدي',
      'Plan your pension': 'خطط لمعاشك التقاعدي',
      'Your situation changes': 'تغييرات في وضعيّتك',
      'About us': 'معلومات عنا',
      Contact: 'تواصل معنا',
      Login: 'تسجيل الدخول',
      GreetingMinimal: 'مرحبًا، اسمي جميلة.',
      DisplayCloseApplicationButton: 'أغلق التطبيق',
      'Need help?': 'تحتاج مساعدة؟',
      ProcessLetter: 'سأقوم الآن بمعالجة رسالتك حتى نتمكن من إجراء بعض التغييرات لمساعدتك في فهم المعلومات بشكل أفضل.',
      PreviewLetter: 'هذا معاينة لرسالتك. يرجى الانتظار بينما نقوم بمعالجتها لك.',
      ChatWithUsTitle: 'دردش معنا',
      ChatWithUsText: 'اسأل سؤالك عبر الدردشة. في أيام الأسبوع بين الساعة 08:30 و 17:00.',
      ChatWithUsButton: 'ابدأ دردشة',
      EmailUsTitle: 'أرسل لنا بريدًا إلكترونيًا',
      EmailUsText: 'للأسئلة الشاملة، يرجى إرسال بريد إلكتروني إلينا.',
      EmailUsButton: 'أرسل رسالة',
      CallUsTitle: 'اتصل بنا',
      CallUsText: 'يمكنك الاتصال بنا على الرقم 020 583 51 00.',
      Welcome1: 'مرحبًا بك في تكست بادي. هنا يمكنك الحصول على مساعدة لفهم معلومات التقاعد الخاصة بك بشكل أفضل.',
      Welcome2: 'يمكنك على سبيل المثال اختيار لغة بحيث يمكن ترجمة المعلومات بالنسبة لك.',
      ChooseLanguageButton: 'اختر لغة',
      SuccessfullyChangingLanguage: 'رائع! تم تعيين لغتك الآن إلى العربية.',
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'nl',
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage'],
      caches: ['localStorage', 'cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
