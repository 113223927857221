import { ListItem, ListItemButton, List, styled } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { languages, Language } from '../data/languages';

const LanguagesList = styled(List)({
    marginBottom: '30px',
    padding: '0',
    backgroundColor: '#fff',
    borderRadius: '30px',
    overflow: 'hidden',
});

const LanguageItem = styled(ListItem)({
    borderBottom: '1px solid #C7E1F3',
    '&:hover': {
        backgroundColor: '#e0e0e0',
    },
    '&:last-of-type': {
        borderBottom: 'none',
    },
    '& .MuiListItemButton-root': {
        paddingTop: '12px',
        paddingBottom: '12px',
    },
    b: {
        marginLeft: '16px',
    },
});

interface LanguageListProps {
    handleLanguageClick: (language: string) => void;
    selectedLanguage: string | null;
}

const LanguageList: React.FC<LanguageListProps> = ({ selectedLanguage, handleLanguageClick }) => {

    const LanguageItemRow: React.FC<{ language: string } & Language> = ({ language, flag, title, extraText }) => {
        const isSelected = selectedLanguage === language;

        return (
            <LanguageItem disablePadding>
                <ListItemButton
                    key={language}
                    onClick={() => handleLanguageClick(language)}
                    style={{
                        opacity: selectedLanguage === null ? 1 : isSelected ? 1 : 0.3,
                    }}
                >
                    <div className="wrap-language-svg">{flag}</div>
                    <div>
                        <b>{title}</b>
                        {extraText && (
                            <>
                                &nbsp;<span>({extraText})</span>
                            </>
                        )}
                    </div>
                    {isSelected && <CheckIcon style={{ position: 'absolute', right: '16px', color: '#0D58A8' }} />}
                </ListItemButton>
            </LanguageItem>
        );
    };

    return (
        <LanguagesList>
            {(Object.keys(languages) as string[]).map((languageKey) => (
                <LanguageItemRow key={languageKey} language={languageKey} {...languages[languageKey]} />
            ))}
        </LanguagesList>
    );
};

export default LanguageList;
