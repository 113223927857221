import { Container, Box, styled, Grid, Card, CardMedia, CardContent } from '@mui/material';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';
import BlueWebsite from '../assets/images/website-blue.svg';
import RedWebsite from '../assets/images/website-red.svg';
import DarkRedWebsite from '../assets/images/website-darkRed.svg';
import YellowWebsite from '../assets/images/website-yellow.svg';
import DarkBlueWebsite from '../assets/images/website-darkBlue.svg';
import JamilaCollapsed from '../components/JamilaCollapsed';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useIdleTimer } from 'react-idle-timer';

const WebsiteBox = styled(Box)({
  h1: {
    marginTop: '32px',
    marginBottom: '16px',
    fontSize: '32px',
    lineHeight: 1.2,
    color: '#313233',
  },
  '& .card-content-header': {
    color: '#313233',
    'font-family': ['Open Sans-Semibold', 'Helvetica'],
    'font-size': '24px',
    'font-weight': 600,
    'line-height': '32px',
  },
  '& .card-content-text': {
    // marginBottom: '25px',
    color: '#333333',
    'font-family': ['Open Sans-Regular', 'Helvetica'],
    'font-size': '14px',
    'font-weight': 400,
    'line-height': '20px',
  },
  span: {
    color: '#0D58A8',
    textDecoration: 'underline',
    'font-family': ['Open Sans-Regular', 'Helvetica'],
    'font-size': '16px',
    'font-weight': 400,
    'line-height': '18px',
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  '& .MuiButton-root': {
    marginBottom: '25px',
  },
  '& .MuiCard-root': {
    marginTop: '16px',
    marginBottom: '16px',
    'border-radius': '8px',
    'background-color': '#ffffff',
    overflow: 'hidden',
  },
  '& .MuiCardContent-root': {
    display: 'flex',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    gap: '16px',
    padding: '24px 16px 16px',
    flex: '0 0 auto',
    flexDirection: 'column',
    a: {
        color: '#e94235'
    }
  },
});

const StickyHeaderArea = styled(Box)({
  position: 'fixed',
  top: 0,
  width: '100%',
  // Other necessary styles...
});

const StickyFooterArea = styled(Box)({
  position: 'fixed',
  bottom: 0,
  width: '100%',
  justifyContent: 'center',
  display: 'flex',
  flex: 1,
  // Other necessary styles...
});

interface WebsiteProps {
  onNext?: () => void;
}

interface LinkProps {
  children?: React.ReactNode;
  to: string;
}

const Link: React.FC<LinkProps> = ({ children, to }) => (
  <a href={to}>
    {children}
  </a>
);

const WebsiteFlow: React.FC<WebsiteProps> = ({ onNext }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onIdle = () => {
    navigate('/?initialState=idleNotification');
  };
  useIdleTimer({
    onIdle,
    timeout: 10_000,
  });

  const onNavigateToTerms = useCallback(() => {
    navigate('/website/terms');
  }, [navigate]);

  return (
    <>
      <StickyHeaderArea>
        <Header onNavigateToTerms={onNavigateToTerms} />
      </StickyHeaderArea>
      <Container style={{ paddingTop: '64px', paddingBottom: '64px' }}>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} md={6}>
            <WebsiteBox>
              <h1>Waar wil je meer informatie over?</h1>
              <Card>
                <CardMedia image={BlueWebsite} sx={{ height: 250 }} />
                <CardContent>
                  <div className="card-content-header">Alles over mijn pensioenregeling</div>
                  <div className="card-content-text">
                    Op MijnPensioenschoonmaak ziet u hoeveel <Link to="/?initialState=glossaryIntro&term=pention">pensioen</Link> u heeft opgebouwd. Ook vindt u gemakkelijk informatie over uw
                    pensioen.
                  </div>
                  <span>
                    Bekijk wat er voor mij is geregeld
                    <ChevronRightIcon />
                  </span>
                </CardContent>
              </Card>
              <Card>
                <CardMedia image={RedWebsite} sx={{ height: 250 }} />
                <CardContent>
                  <div className="card-content-header">Wanneer doe ik wat?</div>
                  <div className="card-content-text">
                    Verandert er iets in uw werk, relatie of gaat u verhuizen? Bekijk wat dat betekent voor uw <Link to="/?initialState=glossaryIntro&term=pention">pensioen</Link>. En wat u misschien
                    moet regelen.
                  </div>
                  <span>
                    Mijn situatie verandert
                    <ChevronRightIcon />
                  </span>
                </CardContent>
              </Card>
              <Card>
                <CardMedia image={DarkRedWebsite} sx={{ height: 250 }} />
                <CardContent>
                  <div className="card-content-header">Hoe begin ik?</div>
                  <div className="card-content-text">
                    Op MijnPensioenschoonmaak staat informatie over uw <Link to="/?initialState=glossaryIntro&term=pention">pensioen</Link>. U ziet bijvoorbeeld hoeveel pensioen u heeft. En welke
                    gegevens wij van u hebben.
                  </div>
                  <span>
                    Bekijk mijn gegevens
                    <ChevronRightIcon />
                  </span>
                </CardContent>
              </Card>
              <Card>
                <CardMedia image={YellowWebsite} sx={{ height: 250 }} />
                <CardContent>
                  <div className="card-content-header">Hoeveel pensioen heb ik?</div>
                  <div className="card-content-text">
                    Elke maand gaat een deel van uw salaris naar uw pensioenpotje. Uw werkgever spaart ook mee. U hoeft daar niets voor te doen. Het pensioenfonds zorgt voor uw geld.
                  </div>
                  <span>
                    Bekijk de hoogte van uw pensioen
                    <ChevronRightIcon />
                  </span>
                </CardContent>
              </Card>
              <Card>
                <CardMedia image={DarkBlueWebsite} sx={{ height: 250 }} />
                <CardContent>
                  <div className="card-content-header">Hoeveel pensioen heb ik?</div>
                  <div className="card-content-text">
                    Elke maand gaat een deel van uw salaris naar uw pensioenpotje. Uw werkgever spaart ook mee. U hoeft daar niets voor te doen. Het pensioenfonds zorgt voor uw geld.
                  </div>
                  <span>
                    Bekijk de hoogte van uw pensioen
                    <ChevronRightIcon />
                  </span>
                </CardContent>
              </Card>
            </WebsiteBox>
          </Grid>
        </Grid>
      </Container>
      <StickyFooterArea>
        <Link to="/?initialState=idleNotification">
          <JamilaCollapsed />
        </Link>
      </StickyFooterArea>
    </>
  );
};

export default WebsiteFlow;
