import React, { ChangeEvent, useRef, useImperativeHandle } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { languages } from '../data/languages';
import imageCompression from 'browser-image-compression';
import { MIDDLEWARE_OCR, MIDDLEWARE_UPLOAD_IMAGE } from '../config';

interface ImageUploaderProps {
  onUploadSuccess: (response: any) => void;
  onUploadFailure: (error: any) => void;
  onUploadCancel: () => void;
  onImageCapture: (file: File) => void;
}

const ImageUploader: React.ForwardRefRenderFunction<HTMLInputElement, ImageUploaderProps> = ({ onUploadSuccess, onUploadFailure, onUploadCancel, onImageCapture }, ref) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { i18n } = useTranslation();

  useImperativeHandle(ref, () => fileInputRef.current!, []);

  const postOcrs = async (imgUrl: string) => {
    try {
      const responseOcrs = await axios.post(`${MIDDLEWARE_OCR}`, { imageUrl: imgUrl, outputLanguage: languages[i18n.language].name });

      if (responseOcrs.data) {
        onUploadSuccess(responseOcrs.data);
      }
    } catch (error) {
      console.error('Error during data fetch:', error);
    }
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      try {
        const options = {
          maxSizeMB: 2,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(selectedFile, options);

        onImageCapture(compressedFile);

        const formData = new FormData();
        formData.append('image', compressedFile);

        const response = await axios.post(`${MIDDLEWARE_UPLOAD_IMAGE}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response.data) {
          postOcrs(response.data);
        }
      } catch (error) {
        onUploadFailure(error);
        console.error('Error during upload:', error);
      }
    } else {
      onUploadCancel();
    }
  };

  return (
    <>
      <input ref={fileInputRef} id="fileInput" type="file" accept="image/*" capture="user" style={{ display: 'none' }} onChange={handleFileChange} />
    </>
  );
};

export default React.forwardRef(ImageUploader);
