import { Container, Box, styled, Avatar } from '@mui/material';
import Logo from '../assets/images/logo.svg';
import LogoIcon from '../assets/images/logo-icon.svg';
import IconMenu from '../assets/images/icon-menu.svg';
import { languages } from '../data/languages';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import LanguageDialog from './LanguageDialog';
import MainMenuDialog from './MainMenuDialog';
import { useMediaQuery } from 'react-responsive';

const HeaderBox = styled(Box)({
  backgroundColor: '#fff',
});

const CustomAvatar = styled(Avatar)({
  width: '48px',
  height: '48px',
  marginLeft: '16px',
  backgroundColor: '#F2F0EB',
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.1)', // Add any hover effect you prefer
  },
});

interface HeaderProps {
  onNavigateToTerms: () => void;
  disableHeaderButtons?: boolean;
}

const Header = ({ onNavigateToTerms, disableHeaderButtons }: HeaderProps) => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const [languagePickerOpen, setLanguagePickerOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const onHandleLanguagePickerOpen = () => {
    setLanguagePickerOpen(true);
  };

  const onHandleLanguagePickerClose = (navigateToTerms: boolean) => {
    setLanguagePickerOpen(false);
    if (navigateToTerms) {
      onNavigateToTerms();
    }
  };

  const isWebsitePage = location.pathname === '/website';

  return (
    <HeaderBox>
      <Container>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx={{ padding: '8px 0' }}>
          {isMobile ? (
            <Box display={'flex'} alignItems={'center'}>
              <img src={LogoIcon} height={'31'} alt="Logo Pensionen Schoonmaak" />
              <Box sx={{ marginLeft: '10px', color: '#0d58a8' }}>
                <b>Textbuddy</b>
              </Box>
            </Box>
          ) : (
            <Box>
              <img src={Logo} alt="Logo Pensionen Schoonmaak" />
            </Box>
          )}
          {!isMobile && !isWebsitePage && (
            <Box sx={{ color: '#0d58a8' }}>
              <b>Textbuddy</b>
            </Box>
          )}
          <Box display={'flex'} alignItems={'center'}>
            {!disableHeaderButtons && (
              <CustomAvatar onClick={onHandleLanguagePickerOpen}>
                <div className="wrap-language-svg">{i18n.language && languages[i18n.language].flag}</div>
              </CustomAvatar>
            )}
            {!disableHeaderButtons && (
              <CustomAvatar onClick={() => setMenuOpen(true)}>
                <img src={IconMenu} width={'24'} alt="Menu" />
              </CustomAvatar>
            )}
          </Box>
        </Box>
      </Container>
      <LanguageDialog open={languagePickerOpen} onClose={onHandleLanguagePickerClose} />
      <MainMenuDialog
        onChangeLanguage={() => {
          setMenuOpen(false);
          setLanguagePickerOpen(true);
        }}
        onClose={() => {
          setMenuOpen(false);
        }}
        open={menuOpen}
      />
    </HeaderBox>
  );
};

export default Header;
