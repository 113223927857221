import { DE, GB, NL, PL, SA, ES, UA } from 'country-flag-icons/react/3x2';

export type Language = {
  title: string;
  flag: React.ReactNode;
  name: string;
  extraText?: string;
};

export const languages: { [key: string]: Language } = {
  'nl': { title: 'Nederlands',  name: 'Dutch', flag: <NL title="Nederlands" />, extraText: 'origineel' },
  'en': { title: 'English', name: 'English', flag: <GB title="English" /> },
  'de': { title: 'Deutsch', name: 'German', flag: <DE title="Deutsch" /> },
  'pl': { title: 'Polski', name: 'Polish', flag: <PL title="Polski" /> },
  'es': { title: 'Español', name: 'Spanish', flag: <ES title="Español" /> },
  'uk': { title: 'Українська мова', name: 'Ukrainian', flag: <UA title="Українська мова" /> },
  'ar': { title: 'العربية', name: 'Arabic', flag: <SA title="العربية" /> },
};