import React from 'react';
import { Box, Typography, styled } from '@mui/material';

const StyledFrame = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: '#0d58a8',
  borderRadius: '16px 16px 0 0',
  display: 'inline-flex',
  gap: theme.spacing(2),
  justifyContent: 'center',
  height: '36px',
  padding: theme.spacing(1, 4),
  position: 'relative',
  '& .hulp-nodig': {
    color: '#ffffff',
    fontFamily: '"Open Sans-Regular", Helvetica',
    fontSize: '13px',
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: 'normal',
    position: 'relative',
    width: 'fit-content',
  },
}));

const JamilaCollapsed = (): JSX.Element => {
  return (
    <StyledFrame>
      <Typography className="hulp-nodig" variant="body2">
        Hulp nodig?
      </Typography>
    </StyledFrame>
  );
};

export default JamilaCollapsed;
