import axios from "axios";
import { useCallback, useState } from "react";
import { ProcessDTO } from "../../../generated/api/apg-middleware";
import { useTranslation } from "react-i18next";
import { languages } from "../../../data/languages";
import { MIDDLEWARE_GLOSSARY } from "../../../config";

export const useGetGlossaryEntryCallback = () => {



    const [loadingCounter, setLoadingCounter] = useState(0);
    const { i18n } = useTranslation();


    const getGlossaryEntry = useCallback((term: string) => {
        setLoadingCounter((l) => l + 1);

        return axios
            .get(`${MIDDLEWARE_GLOSSARY}?term=${term}&language=${languages[i18n.language].name}`)
            .then((response) => {
                setLoadingCounter((l) => l - 1);

                if (response.data) {
                    const processDto: ProcessDTO = response.data;
                    return processDto;
                }
            })
            .catch((err) => {
                console.error(err);
                setLoadingCounter((l) => l - 1);
                return Promise.reject(`Failed to load glossary entry ${term}`)
            });
    }, [i18n.language]);

    return { loadingCounter: loadingCounter, getGlossaryEntry }

}