import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import LanguageList from './LanguageList';
import { Button, DialogActions, DialogContent } from '@mui/material';



export interface LanguageDialogProps {
    open: boolean;
    onClose: (navigateToTerms: boolean) => void;
}

function LanguageDialog(props: LanguageDialogProps) {
    const { onClose, open } = props;
    const { i18n, t } = useTranslation();
    const [selectedLanguage, setLanguage] = React.useState<string | null>(i18n.language);

    const handleLanguageClick = (language: string) => {
        if (selectedLanguage === language) {
            setLanguage(null);
        } else {
            setLanguage(language);
        }
    };

    const handleContinueClick = () => {
        if (selectedLanguage) {
            i18n.changeLanguage(selectedLanguage);
            onClose(true);

        }
    };


    const handleClose = () => {
        onClose(false);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{t("Set language")}</DialogTitle>
            <DialogContent>
                <LanguageList handleLanguageClick={handleLanguageClick} selectedLanguage={selectedLanguage} />
            </DialogContent>
            <DialogActions>
                {selectedLanguage && (
                    <Button variant="contained" fullWidth onClick={handleContinueClick}>
                        {t("Switch language")}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default LanguageDialog;