import { Container, Box, styled, Checkbox, FormControlLabel, FormGroup, Button, Grid } from '@mui/material';
import Header from '../components/Header';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const TermsBox = styled(Box)({
  h1: {
    marginTop: '54px',
    marginBottom: '25px',
    fontSize: '32px',
    lineHeight: 1.2,
  },
  p: {
    marginBottom: '25px',
    lineHeigth: '24px',
  },
  a: {
    color: '#0D58A8',
    textDecoration: 'underline',
  },
  '& .MuiFormGroup-root': {
    marginBottom: '25px',
    '& .MuiFormControlLabel-root': {
      alignItems: 'flex-start',
      marginLeft: '0',

      '& .MuiCheckbox-root': {
        padding: '0',
        marginRight: '8px',
        '&.Mui-checked': {
          color: '#0D58A8',
        },
      },

      '& .MuiTypography-root': {
        marginTop: '2px',
        fontSize: '14px',
        lineHeight: 1.5,
      },
    },
  },
  '& .MuiButton-root': {
    marginBottom: '25px',
  },
});

interface TermsProps {
  onNext: () => void;
}

const Terms: React.FC<TermsProps> = ({ onNext }) => {
  const { t, i18n } = useTranslation();
  const [showFullContent, setShowFullContent] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const toggleShowFullContent = () => {
    setShowFullContent(!showFullContent);
  };

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  return (
    <>
      <Header onNavigateToTerms={() => { }} />
      <Container>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} md={6}>
            <TermsBox>
              <h1>{t("Terms of using translations")}</h1>
              <p>
                {
                  // showFullContent
                  //   ?
                  t("Translation disclaimer")
                  // : `This document or this website has been translated for your convenience from Dutch into other languages using translation software. Machine-generated translations may contain inaccuracies...`
                }
                {/* {' '}
                <a onClick={toggleShowFullContent}>{showFullContent ? t("Read less") : t("Read more")}</a> */}
              </p>

              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
                  label={t("I have read the terms above and understand and agree before setting the default language to language")}
                />
              </FormGroup>
              <Button variant="contained" fullWidth disabled={!isChecked} onClick={onNext}>
                {t("Continue in language")}
              </Button>
              {i18n.language !== 'nl' && (
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => {
                    i18n.changeLanguage("nl")
                    onNext();
                  }}
                >
                  {t("Switch to Dutch (original)")}
                </Button>
              )}
            </TermsBox>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Terms;
