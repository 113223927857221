import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import { DialogContent, List, ListItem, ListItemButton, styled } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';


const LanguagesList = styled(List)({
    marginBottom: '30px',
    padding: '0',
    backgroundColor: '#fff',
    borderRadius: '30px',
    overflow: 'hidden',
});

const LanguageItem = styled(ListItem)({
    borderBottom: '1px solid #C7E1F3',
    '&:hover': {
        backgroundColor: '#e0e0e0',
    },
    '&:last-of-type': {
        borderBottom: 'none',
    },
    '& .MuiListItemButton-root': {
        paddingTop: '12px',
        paddingBottom: '12px',
    },
    b: {
        marginLeft: '16px',
    },
});


export interface MainMenuDialogProps {
    open: boolean;
    onChangeLanguage: () => void;
    onClose: (navigateToTerms: boolean) => void;
}

function MainMenuDialog(props: MainMenuDialogProps) {
    const { onClose, open, onChangeLanguage } = props;
    const { t } = useTranslation();


    const handleClose = () => {
        onClose(false);
    };

    const LanguageItemRow: React.FC<{ menuText: string, extraText?: string, onClick: () => void, Icon?: React.ElementType }> = ({ menuText, extraText, onClick, Icon }) => {

        return (
            <LanguageItem disablePadding>
                <ListItemButton
                    key={menuText}
                    onClick={onClick}
                    style={{
                        opacity: 1,
                    }}
                >
                    <div>
                        <b>{t(menuText)}</b>
                        {extraText && (
                            <>
                                &nbsp;<span>({t(extraText)})</span>
                            </>
                        )}

                    </div>
                    {Icon && (<Icon />)}

                </ListItemButton>
            </LanguageItem>
        );
    };
    const menuItems = [
        { menuText: t("ChangeLanguage"), onClick: onChangeLanguage },
        {
            menuText: t("Uw Pension"),
            Icon: OpenInNewIcon,
            onClick: () => {
                window.open("https://www.pensioenschoonmaak.nl/uw-pensioen", '_blank');
                handleClose();
            }
        },
        {
            menuText: t("Plan uw pensioen"),
            Icon: OpenInNewIcon,
            onClick: () => {
                window.open("https://www.pensioenschoonmaak.nl/plan-uw-pensioen", '_blank');
                handleClose();
            }
        },
        {
            menuText: t("Uw situatie verandert"),
            Icon: OpenInNewIcon,
            onClick: () => {
                window.open("https://www.pensioenschoonmaak.nl/uw-situatie-verandert", '_blank');
                handleClose();
            }
        },
        {
            menuText: t("Over ons"),
            Icon: OpenInNewIcon,
            onClick: () => {
                window.open("https://www.pensioenschoonmaak.nl/over-ons", '_blank');
                handleClose();
            }
        },
        {
            menuText: t("Contact"),
            Icon: OpenInNewIcon,
            onClick: () => {
                window.open("https://www.pensioenschoonmaak.nl/contact", '_blank');
                handleClose();
            }
        },
        {
            menuText: t("Inloggen"),
            Icon: OpenInNewIcon,
            onClick: () => {
                window.open("https://mijn.pensioenschoonmaak.nl/account/login?TAM_OP=login", '_blank');
                handleClose();
            }
        },
    ]

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{t("Menu")}</DialogTitle>
            <DialogContent>

                <LanguagesList>
                    {menuItems.map((menuItem) => (
                        <LanguageItemRow key={menuItem.menuText} menuText={menuItem.menuText} onClick={menuItem.onClick} Icon={menuItem.Icon} />
                    ))}
                </LanguagesList>
            </DialogContent>
        </Dialog>
    );
}

export default MainMenuDialog;